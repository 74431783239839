import React from "react";
import styled from "@emotion/styled";

const Text = styled.p`
    font-size: 1.1rem;
    line-height: 1.8rem;
    word-spacing: 1px;
    letter-spacing: 0.3px;
`;

function MainContent (props) {
    return (
            <Text>{props.text}</Text>
    )
}

export default MainContent;
