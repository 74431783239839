import Config from '../config';

const makeApiRequest = (path, method, body) => {
    return fetch(Config.api.baseUrl + path, {
        method,
        body
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw new Error(`Error receiving request`);
    }).then(res => {
        return res.data;
    });
};

export const getStats = () => {
    return makeApiRequest(Config.api.cumulativeStats, "GET");
};

export const getConstituents = () => {
    return makeApiRequest(Config.api.constituentsInfo, "GET");
};

export const getChartDataByRange = (startDate, endDate) => {
    return makeApiRequest(Config.api.chartRangeData(startDate, endDate), "GET");
};

export const getCurrentChartData = () => {
    return makeApiRequest(Config.api.chartDataToday, "GET");
};
