import React from "react";
import styled from "@emotion/styled";

const FooterDetails = styled.div`
    margin: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid rgb(210, 210, 214);
`;

const DataSource = styled.div`
`;

const CopyRight = styled.div``;

function Footer () {
    const fullYear = new Date().getFullYear();
    return(
            <FooterDetails>
                <DataSource>Market cap data source: <a href={"https://coinmarketcap.com/"} target={"_blank"} rel="noopener noreferrer">Coinmarketcap</a>; quotes data source: <a href={"https://coinmarketcap.com/"} target={"_blank"} rel="noopener noreferrer">Coin API.</a>
                </DataSource>
                <CopyRight>
                    All rights reserved &copy; {fullYear}
                </CopyRight>
            </FooterDetails>
    )
}

export default Footer;
