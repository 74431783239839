import React from "react";
import styled from "@emotion/styled";
import Header from "../components/HeaderComponent/headers.js";
import MainContent from "../components/text-component/text-component.js";


const AboutSectionContainer = styled.div`
    margin:5rem auto;
    @media(min-width:768px){
        width:70%;
    }
`;


function AboutSection () {
    return (
            <AboutSectionContainer id={"about"}>
                <Header text={"About"}/>
                <MainContent
                text={
                    "BARWI30 is currently a proprietary index but can be used for website display and academic " +
                    "research without permission. Any other use is construed as commercial and proper permissions are needed"
                }
                />
            </AboutSectionContainer>
    )
}

export default AboutSection;
