import React from "react";
import MainContent from "../components/text-component/text-component";
import Header from "../components/HeaderComponent/headers";
import Images from "../components/image-component/image";
import styled from "@emotion/styled";

const CalculationContainer = styled.div`
    margin: 5rem auto;
    @media(min-width:768px){
        width:70%;
    }
`;
const Formula = styled.em`
    display: inline-block;
`;

function Calculation() {
    return (
            <CalculationContainer id={"calculation"}>
                <Header text={"BARWI30 Calculation"}/>
                <MainContent
                        text={"The methodology behind the BARWI30 is pretty straightforward.\n" +
                        "Constituent unscaled weights are calculated by\n"}
                />
                <Images src={"https://res.cloudinary.com/alexandrah/image/upload/v1590221193/Coin-base/unscaled_weights_j1ve0c.png"}/>
                <MainContent text={"Where;"}/>
                <Formula>uwc=The inverse of unscaled weight assigned to each cryptocurrency within the
                    portfolio</Formula>
                <Formula>SDc=This is the 30 day moving average of the standard deviation of logged data of each
                    cryptocurrency</Formula>
                <MainContent
                        text={
                            "We scale the standard deviation on a 30 day moving average so that risk is adjusted consistently " +
                            "based on monthly data. It is then compared to the summed value of all standard deviations " +
                            "within the portfolio. It is assumed we have a total monetary value of 100 to assign to the portfolio." +
                            "Data needs to be rescaled as we need to assign more value to the lowest risk hence coin weights are calculated as follows"
                        }
                />
                <Images src={"https://res.cloudinary.com/alexandrah/image/upload/v1590221673/Coin-base/coin-weight_k7c9kk.png"}/>
                <MainContent text={"Where;"}/>
                <Formula>wc=actual weight assigned to each cryptocurrency within the protfolio</Formula>
                <MainContent text={"The index is calculated thus:"}/>
                <Images src={"https://res.cloudinary.com/alexandrah/image/upload/v1588442491/Coin-base/index_ecr8e9.png"}/>
                <MainContent
                        text={
                            "Where rc is the new value of a particular cryptocurrency after the daily price change has been effected. It can be represented mathematically as "
                        }
                />
                <Images src={"https://res.cloudinary.com/alexandrah/image/upload/v1588442582/Coin-base/dailyprice_tdc8o7.png"}/>
                <MainContent
                        text={
                            "Where %∆pc is the percentage change in price of a particular cryptocurrency using 1st January " +
                            "2019 as the base date or a subsequent date in cryptocurrencies that are more recent than they date.\n."
                        }
                />
            </CalculationContainer>
    )

}

export default Calculation;
