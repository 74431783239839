import React from "react";
import styled from "@emotion/styled";
import MainContent from "../components/text-component/text-component";
import Header from "../components/HeaderComponent/headers";

const TeamContainer = styled.div`
    margin:5rem auto;
    @media(min-width:768px){
        width:70%;
    }
`;


function Team () {
   return (
           <TeamContainer id={"team"}>
               <Header text={"The Team"}/>
               <MainContent
                       text={
                           "BARWI30 was developed by Chiagozie Iwu, founder of CI Cryptosolutions while API and data " +
                           "analytics are maintained by Chizitere Ezennia and Onyinye Mbamara."
                       }
               />
           </TeamContainer>
   )

}

export default Team;
