import React from "react";
import styled from "@emotion/styled";
import Header from "../components/HeaderComponent/headers";
import Images from "../components/image-component/image";
import {getStats} from "../services/BarwiApiService";

const IndexTable = styled.div`
    margin: 5rem auto;
    border: 2px solid #f1f2f5;
    background-color: #f1f5f6b3;
    @media(min-width: 768px){
        width: 70%;
    }
     table {
        margin: 0 auto;
        border-collapse: collapse;
        & td {
            padding: 1rem 2rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 300;
            &.right {
                text-align: right;
            }
            &.left {
                text-align: left;
            }
        }
    }
`;

const Table = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    margin-bottom: 1rem;

`;
const Columns = styled.div`
    width: 50%;

`;
const Rows = styled.div`
    margin: auto 0.5rem;
    font-weight: 300;
    padding: 0.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1rem;
    border-bottom: 1pxd solid;
`;


class IndexValue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexValue: {},
            isLoading: true,
            errorMessage: ''
        };
    }

    componentDidMount() {
        getStats().then(data => {
            this.setState({
                indexValue: data,
                isLoading: false
            });
        }).catch(err => {
            this.setState({
                isLoading: false,
                errorMessage: "There was an issue retrieving data, kindly refresh your page"
            });
            console.log(`there was an error while fetching data: ${err}`);
        })

    }

    render() {
        const indexStats = this.state.indexValue;
        const {isLoading, errorMessage} = this.state; 
        const getIndexStats = (key) => indexStats[key].percentChange.toFixed(2)
        const rightColumn = 'right';
        const leftColumn = 'left';
        return (
                <IndexTable id={"indexvalues"}>
                    <Header text={"Index Values"}/>
                    {
                        !isLoading ?
                        <div>
                            {indexStats.current ?      
                            <table>
                                <thead></thead>
                                <thead></thead>
                                {indexStats.current ? 
                                <tr> 
                                    <td className={rightColumn}>Real time Index value</td>
                                    <td className={leftColumn}>{indexStats.current.indexPrice.toFixed(2)}</td>
                                </tr>
                                : null}
                                {indexStats["24h"] ?
                                    <tr>
                                        <td className={rightColumn}>24-hour point move</td>
                                        <td className={leftColumn}>{indexStats["24h"].difference.toFixed(2)}</td>     
                                    </tr>
                                : null }
                                {indexStats["24h"] ?
                                <tr>
                                    <td className={rightColumn}>24-hour change</td>
                                    <td className={leftColumn}>{getIndexStats('24h')}%</td>   
                                </tr>
                                : null}
                                {indexStats["1w"] ?
                                <tr>
                                    <td className={rightColumn}>7-day change</td>
                                    <td className={leftColumn}>{getIndexStats("1w")}%</td>   
                                </tr>
                                : null }
                                {indexStats["30d"] ?
                                <tr>
                                    <td className={rightColumn}>30-day change</td>
                                    <td className={leftColumn}>{getIndexStats("30d")}%</td>  
                                </tr>
                                : null }
                                {indexStats["1y"] ?
                                <tr>
                                    <td className={rightColumn}>365-day change</td>
                                    <td className={leftColumn}>{getIndexStats("1y")}%</td> 
                                </tr>
                                : null }
                            </table>
                            :
                                <p>{errorMessage}</p>
                            }
                        </div> :
                        <Images src={"https://res.cloudinary.com/alexandrah/image/upload/c_scale,w_250/v1588376115/Coin-base/Loading_icon_irn4we.gif"}/>
                    }
                </IndexTable>
        )
    }
}

export default IndexValue;
