import React from "react";
import styled from "@emotion/styled";

const Image = styled.img`
    display:block;
    margin:0 auto;
    padding-bottom: 1rem;
`;

function Images (props) {
    return(
        <Image src={props.src} alt={props.alt} width={props.width}/>
    )
}

export default Images;
