import React from "react";
import styled from "@emotion/styled";

const Heading = styled.h1`
    color: #333;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 8.4px;
    margin: 1.5rem auto;
`;

function Header (props) {
    return (
            <Heading>{props.text}</Heading>
    )
}

export default Header;
