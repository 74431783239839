import React from "react";
import styled from "@emotion/styled";
import MainContent from "../components/text-component/text-component";
import Header from "../components/HeaderComponent/headers";
import Images from "../components/image-component/image";
import {getConstituents} from "../services/BarwiApiService.js";


const ConstituentsDetails = styled.div`
    margin: 5rem auto;
    @media(min-width:768px){
        width:70%;
    }
`;

const ConstituentsData = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media(min-width:1024px){
        grid-template-columns: repeat(4, 1fr);
    }
`;

const CurrencyDetails = styled.span`
    display: inline-block;
    margin: 1rem 2rem;
    width: 80px;
    text-align: center;
    @media(max-width: 768px){
        margin: 1rem;
    }
`;

const CurrencySymbol = styled.span`
    display: inline;
`;

class Constituents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            constituents: [],
            errorMessage: '',
            isLoading: true,
        };
    }

    componentDidMount() {
        getConstituents().then(data => {
            this.setState({
                constituents: data,
                isLoading: false
            });
        }).catch(err => {
            this.setState({
                errorMessage: "Still trying to fetch currencies, please refresh your page",
                isLoading: false
            });
        });
    }

    render() {
        const constituents = this.state.constituents;
        return (
                <ConstituentsDetails id={"constituents"}>
                    <Header text={"Constituents"}/>
                    <MainContent
                            text={
                                "The criteria used in selecting the 30 cryptocurrencies in the BARWI30 are;" +
                                " Market Capitalization, Usability and long term viability of the Cryptocurrency, " +
                                "Consistency. Stable coins are not considered."
                            }
                    />
                    <MainContent
                            text={
                                "The constituents would be reviewed on a regular basis and any cryptocurrency could be " +
                                "replaced if it falls short in any of the criteria listed. The currently selected cryptocurrencies are "
                            }
                    />
                    {!this.state.isLoading
                            ? constituents.length < 1 ?
                                    this.state.errorMessage :
                                    <ConstituentsData>
                                        {
                                            constituents.map((constituent) =>
                                                    <CurrencyDetails key={constituent.id}>
                                                        <Images src={constituent.logo} width={"50%"}
                                                                alt={constituent.name}/>
                                                        <CurrencySymbol>{constituent.symbol}</CurrencySymbol>
                                                    </CurrencyDetails>
                                            )
                                        }
                                    </ConstituentsData>
                            :
                            <Images src={"https://res.cloudinary.com/alexandrah/image/upload/c_scale,w_250/v1588376115/Coin-base/Loading_icon_irn4we.gif"}/>
                    }


                </ConstituentsDetails>
        )
    }
}

export default Constituents;
