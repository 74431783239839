import React from 'react';
import styled from "@emotion/styled";
import MainContent from "../text-component/text-component";

const IntroContainer = styled.div`
    // box-shadow:0 6px 14.6px 1.4px rgba(0, 0, 0, 0.13);
`;

const IntroHeader = styled.div`
    position: relative;
    padding: 0.2rem 1.2rem 0.2rem;
    background-color: #448e44;
    // overflow:hidden;
    // &:after {
    //     content: " ";
    //     position: absolute;
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     top: 10px;
    //     left: 50%;
    //     z-index: 1;
    //     background: #FFF;
    //     transform-origin: bottom left;
    //     -ms-transform: skew(-30deg, 0deg);
    //     -webkit-transform: skew(-30deg, 0deg);
    //     transform: skew(-30deg, 0deg);
    // }
    // @media(max-width:768px){
    //     &:after{
    //     display: none;
    //     }
    }
`;

const IntroTextContainer = styled.div`
    padding: 1.2rem 0.5rem 0;
`;


const IntroTitle = styled.h4`
    font-size: 16px;
    text-align: center;
    margin: 0.3rem;
    letter-spacing: 3px;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    @media(max-width: 768px){
        text-align: center;
    }
`;

function Intro () {
    return (
            <IntroContainer>
                <IntroHeader>
                    <IntroTitle>
                        BARWI 30- Bitcoin Altcoin Risk Weighted index
                    </IntroTitle>
                </IntroHeader>
                <IntroTextContainer>
                    <MainContent text={
                        "\n" +
                    "                        The BARWI30 is a Cryptocurrency Index designed to determine performance of the overall\n" +
                    "                        Cryptocurrency market if risk is used as a determinant in assigning weights to particular\n" +
                    "                        Cryptocurrencies in an overall Cryptocurrency portfolio."

                    }/>
                    <MainContent text={
                        "It requires active management to implement as the investment sizes in particular\n" +
                        "                        cryptocurrencies are constantly altered according to investment risk,\n" +
                        "                        this is not a problem for the cryptocurrency market as trading fees are close to zero and strategy algorithms are easily implementable."
                    }/>
                    <MainContent text={

                       " This index is also a perfect indicator of the overall health of the cryptocurrency markets due to its risk mitigating consideration in portfolio buildup."

                    }/>

                </IntroTextContainer>
            </IntroContainer>
    )
}

export default Intro;
