import React from 'react';
import TopSection from "./container/top-section";
import AboutSection from "./container/about-section";
import Constituents from "./container/constituents";
import Navbar from "./components/navbar-component/Navbar";
import styled from "@emotion/styled";
import IndexValue from "./container/index-value";
import Calculation from "./container/calculation";
import Footer from "./container/footer";
import Team from "./container/team";
import Graph from "./container/graph";

const Container = styled.div`
        margin:0 auto;
        padding: 0 1rem;
        @media(min-width:768px){
            width:70%;
        }
`;

function App() {
    return (
            <div className="App">
                <Navbar/>
                <Container>
                    <TopSection/>
                    <IndexValue/>
                    <Graph/>
                    <Constituents/>
                    <Calculation/>
                    <Team/>
                    <AboutSection/>
                </Container>
                <Footer/>
            </div>
    );
}


export default App;
