import React from "react";
import styled from "@emotion/styled";

const Nav = styled.div`
    margin:2rem;
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 15px;
    border-bottom: 1px solid rgb(210, 210, 214);
    @media(max-width: 576px){
        flex-wrap: wrap;
    }
`;

const LogoName = styled.h3`

`;

const Logo = styled.div`

`;

const MenuItems = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    @media(min-width: 1024px){
        margin-right: 3rem;
    };
    @media(max-width: 576px){
       padding-left: 0;
    }
`;

const Link = styled.a`
    text-decoration: none;
    color: #000;
    &:hover {
        color: #143F14;
    }
`;

const Item = styled.li`
    padding-right: 2rem;
    padding-bottom: 0.5rem;
`;

function Navbar() {
    const menuItems = [
        {
            "id": "indexvalues",
            "text": "Index Values"
        },
        {
            "id": "chart",
            "text": "Chart"
        },
        {
            "id": "constituents",
            "text": "Constituents"
        },
        {
            "id": "calculation",
            "text": "Calculation"
        },
        {
            "id": "team",
            "text": "Team"
        },
        {
            "id": "about",
            "text": "About"
        },
    ];
    return (
            <Nav>
                <Logo>
                    <LogoName>Barwi 30-Bitcoin Altcoin Risk Weighted index</LogoName>
                </Logo>
                <MenuItems>
                    {
                        menuItems.map(menuItem =>
                                <Item key={menuItem.id}>
                                    <Link href={`#${menuItem.id}`}>{menuItem.text}</Link>
                                </Item>
                        )
                    }
                </MenuItems>
            </Nav>
    )
}

export default Navbar;
