import React from "react";
import styled from "@emotion/styled";
import Intro from "../components/intro-component/intro.js";

const TopSectionContainer = styled.div`
    margin:0 auto;
    @media(min-width:768px){
        width:70%;
    }
`;


class TopSection extends React.Component {
    render() {
        return (
                <TopSectionContainer>
                    <Intro/>
                </TopSectionContainer>
        );
    }

}

export default TopSection;
