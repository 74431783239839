export default {
    api: {
        baseUrl: (process.env.NODE_ENV === "development") ? "http://localhost:8080" : "",
        chartRangeData: (startDate, endDate) => `/index-data/charts/range?startDate=${startDate}&endDate=${endDate}`,
        chartDataToday: `/index-data/charts/today`,
        cumulativeStats: `/index-data/stats`,
        constituentsInfo: `/index-data/constituents`
    },
    chart: {
        startDate: "2019-01-01"
    }
};
