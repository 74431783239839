import React from "react";
import {createChart, isBusinessDay} from 'lightweight-charts';
import styled from "@emotion/styled";
import {getChartDataByRange, getCurrentChartData} from "../services/BarwiApiService";
import Config from '../config';
import Header from "../components/HeaderComponent/headers";

const ChartWrapper = styled.div`
    margin: 5rem auto;
    @media(min-width: 768px){
        width: 70%;
    };
`;

const Chart = styled.div`
    position: relative;    
    overflow-x: scroll;
`;

const ToolTip = styled.div`
    width: ${(props) => props.width ? `${props.width}px` : '96px'};
    height: ${(props) => props.height ? `${props.height}px` : '80px'};
    position: absolute;
    display: ${(props) => props.shouldDisplay ? 'block' : 'none'};
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    color: #131722;
    background-color: rgba(255, 255, 255, 1);
    text-align: left;
    z-index: 1000;
    top: ${(props) => props.topPosition ? `${props.topPosition}px` : '12px'};
    left: ${(props) => props.leftPosition ? `${props.leftPosition}px` : '12px'};
    margin: ${(props) => props.margin ? `${props.margin}` : '2px'};
    pointer-events: none;
    border: 2px solid rgba(255, 70, 70, 1);
    border-radius: 2px;
`;

const ToolTipName = styled.div`
    margin: 4px;
    font-weight: 500;
`;
const ToolTipDate = styled.div`
    margin: 4px;
`;
const ToolTipPrice = styled.div`
    margin: 4px;
    font-size: 16px;
`;

class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: null,
            chartData: [],
            lineSeries: null,
            intervalId: null,
            toolTip: {
                shouldDisplay: false,
                leftPosition: null,
                topPosition: null,
                price: 0,
                dateString: '',
                toolTipMargin: 2,
                toolTipWidth: 96,
                toolTipHeight: 80,
            },
            chartWidth: 0,
            chartHeight: 0,
            errorMessage: "",
        };
    }

    componentDidMount() {
        const chartContainer = document.getElementById("chart");
        const newChart = createChart(chartContainer, {width: chartContainer.clientWidth, height: 250});
        const lineSeries = newChart.addLineSeries();
        lineSeries.setData(this.state.chartData.map(dat => ({...dat, value: dat.value.toFixed(2)})));

        this.setState({chart: newChart, lineSeries: lineSeries, chartWidth: chartContainer.clientWidth,}, () => {
            this.updateWithRangeData();
            this.callUpdateChart();
        });
    }

    callUpdateChart = () => {
        let id = setInterval(this.periodicallyPullChartData, 30000);
        this.setState({intervalId: id});
        this.chartCustomization();
        this.createTooltip();
    };

    periodicallyPullChartData = () => {
        (this.state.chartData.length > 0) ? this.updateWithCurrentData() : this.updateWithRangeData();
    };

    updateWithRangeData = () => {
        const {lineSeries} = this.state;
        let endDate = new Date();
        getChartDataByRange(Config.chart.startDate, endDate).then(data => {
            this.setState({chartData: data});
            lineSeries.setData(this.state.chartData.map(dat => ({...dat, value: dat.value.toFixed(2)})))
        }).catch(err => {
            this.setState({
                errorMessage: "There was an issue retrieving data, kindly refresh your page"
            })
        });

    };

    updateWithCurrentData = () => {
        let totalChartData = this.state.chartData.map(dat => ({...dat}));
        let lastItemIndex = totalChartData.length - 1;
        let lastArrayElement = totalChartData[lastItemIndex];
        getCurrentChartData().then(data => {
            let retrievedCurrentData = {};
            retrievedCurrentData.time = data.time;
            retrievedCurrentData.value = data.value.toFixed(2);
            if (retrievedCurrentData.time === lastArrayElement.time) {
                totalChartData[lastItemIndex] = retrievedCurrentData;
            } else {
                totalChartData.push(retrievedCurrentData);
            }
        }).catch(err => {
            this.setState({
                errorMessage: "There was an issue retrieving data, kindly refresh your page"
            })

        })
    };

    chartCustomization = () => {
        this.state.chart.applyOptions({
            watermark: {
                color: 'rgba(11, 94, 29, 0.4)',
                visible: true,
                text: 'BARWI30',
                fontSize: 24,
                horzAlign: 'left',
                vertAlign: 'bottom',
            },
            grid: {
                vertLines: {
                    color: 'rgba(70, 130, 180, 0.5)',
                    style: 1,
                    visible: true,
                },
                horzLines: {
                    color: 'rgba(70, 130, 180, 0.5)',
                    style: 1,
                    visible: true,
                },
            },
        });
        let chartSize = document.getElementById("chart");
        this.state.chart.resize(chartSize.clientWidth, 250);
        this.setState({chartWidth: chartSize.clientWidth, chartHeight: 250});

    };

    createTooltip = () => {
        this.state.chart.subscribeCrosshairMove((param) => {
            let chartWidth = this.state.chartWidth;
            let chartHeight = this.state.chartHeight;
            const toolTipObj = this.state.toolTip;

            function businessDayToString(businessDay) {
                return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
            }

            let dateString = (param.time && isBusinessDay(param.time))
                    ? businessDayToString(param.time)
                    : new Date(param.time * 1000).toLocaleDateString();

            if (!param.time || param.point.x < 0 || param.point.x > chartWidth || param.point.y < 0 || param.point.y > chartHeight) {
                this.setState({toolTip: {...toolTipObj, shouldDisplay: false}});
            } else {
                let price = param.seriesPrices.get(this.state.lineSeries);
                let yAxis = param.point.y;
                let xAxis = param.point.x;
                let toolTipHeight = toolTipObj.toolTipHeight;
                let toolTipWidth = toolTipObj.toolTipWidth;
                let toolTipMargin = toolTipObj.toolTipMargin;
                let leftPosition = xAxis + toolTipMargin;
                let topPosition = yAxis + toolTipMargin;

                if (leftPosition > chartWidth - toolTipWidth) {
                    leftPosition = xAxis - toolTipMargin - toolTipWidth;
                }

                if (topPosition > chartHeight - toolTipHeight) {
                    topPosition = yAxis - toolTipMargin - toolTipHeight;
                }

                this.setState({
                    toolTip: {
                        ...toolTipObj,
                        shouldDisplay: true,
                        dateString: dateString,
                        price: price,
                        leftPosition: leftPosition,
                        topPosition: topPosition,
                    }
                });

            }
        });

    };

    componentWillUnmount() {
        if (this.state.lineSeries) {
            this.state.lineSeries.destroy();
        }
        if (this.state.chart) {
            this.state.chart.remove();
        }
        clearInterval(this.state.intervalId);
    }


    render() {
        let toolTip = this.state.toolTip;
        return (
                <ChartWrapper>
                    <Header text={"Historical Chart"}/>
                    <Chart id={"chart"}>
                        <ToolTip
                                id={"tooltip"}
                                topPosition={toolTip.topPosition}
                                leftPosition={toolTip.leftPosition}
                                height={toolTip.toolTipHeight}
                                width={toolTip.toolTipWidth}
                                shouldDisplay={toolTip.shouldDisplay}
                        >
                            <ToolTipName>BARWI30</ToolTipName>
                            <ToolTipPrice>{toolTip.price}</ToolTipPrice>
                            <ToolTipDate>{toolTip.dateString}</ToolTipDate>
                        </ToolTip>
                    </Chart>
                </ChartWrapper>
        )

    }
}

export default Graph;
